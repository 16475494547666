












































































import { Vue, Component } from 'vue-property-decorator';
import Header from '@/components/common/Header.vue';
import BattleInfo from '@/components/battlesList/BattleInfo.vue';
import eventBus from '@/utils/eventBus';

@Component({
    components: { Header, BattleInfo }
})
export default class BecomePartner extends Vue {
    private mounted(): void {
        eventBus.emit('changeClickSrc', '/img/moni-eye.svg');
    }
}
